import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import {getSrc } from "gatsby-plugin-image"
import arrow2 from "../../../static/fonts/Arrow2.svg"
import * as styles from "./HomePage.module.scss"
import ReactMarkdown from "react-markdown"


// Function to remove dashes and replace them with spaces
const removeDashesAndSpaces = (str) => {
  if (str) {
    return str.replace(/-/g, ' ').trim();
  } else {
    return
  }
  
};

const HomePage = () => {
    return(
        <StaticQuery
            query={graphql`
            {
                allMarkdownRemark(filter: {frontmatter: {title: {eq: "HomePage"}}}) {
                    edges {
                        node {
                          frontmatter {
                            content {
                              type
                              windowText
                              fullText
                              perfumeHasOverlay
                              perfumeLink
                              buttonLabel
                              fullImage {
                                childImageSharp {
                                  gatsbyImageData
                                }
                              }
                              image {
                                childImageSharp {
                                  gatsbyImageData
                                }
                              }
                            }
                          }
                        }
                    }
                }
            }
        `}
            render={(data) => {
                if(!data) return null
                let content = data.allMarkdownRemark.edges[0].node.frontmatter.content
                // const topImageSrc = getSrc(frontmatter.topImage)
              
                return(
                    <section>
                        {content?.map((section) => {
                          switch(true) {
                            case Boolean(section.type == 'fullImage'):
                              return (
                                <div className={styles.doubleImageWrap}>
                                  <img className={styles.fullImage} src={getSrc(section.fullImage)}/>
                                </div>
                              )
                            case Boolean(section.type == 'image'):
                              return (
                                <div className={styles.singleImageWrap}>
                                  <img key={section.image} src={getSrc(section.image)} alt="" />
                                  {section.perfumeHasOverlay ? 
                                  <Link to={`/products/${section.perfumeLink.toLowerCase()}`}>
                                    <div className={styles.shopLinkBtn}>
                                        <span>
                                        {/* Shop perfume {removeDashesAndSpaces(section.perfumeLink)} */}
                                        Shop {section.buttonLabel}
                                        </span>
                                        <img className={styles.arrow} src={arrow2}/>
                                      </div>
                                  </Link>
                                  : null} 
                                </div>
                              )
                              case Boolean(section.type == 'windowText'):
                                return (
                                  <ReactMarkdown className={styles.windowText} children={section.windowText} >
                                  {section.windowText}
                                </ReactMarkdown>  
                                )
                                case Boolean(section.type == 'fullText'):
                                  return (
                                    <ReactMarkdown className={styles.fullText} children={section.fullText} >
                                      {section.fullText}
				                            </ReactMarkdown> 


                                )
                                default: 
                                  return (
                                    <>Data type not recognized</>
                                  )                          
                          }
                        })}

                    </section>
                )
            }}
        />  
    )
}

export default HomePage



 
